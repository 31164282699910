import React from 'react';
import ReactDOM from 'react-dom/client';

// 1. Import the main CSS file which has Tailwind directives
import './index.css';

// 2. Import the root app component
import App from './App';

// 3. Create and render the root
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
