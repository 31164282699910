import React from 'react';

const HomePage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-white relative flex items-center justify-center font-sans">
      {/* Center hero text */}
      <div className="text-center text-white">
        <h1 className="text-5xl font-light mb-2">Hair Design by</h1>
        <h2 className="text-7xl font-bold">Renata</h2>
      </div>

      {/* Circular navigation buttons */}
      {/* Book button - top center */}
      <a 
        href="/book" 
        className="absolute top-[20%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 
                   w-16 h-16 bg-white text-black border-2 border-black rounded-full 
                   flex items-center justify-center no-underline cursor-pointer 
                   animate-float [animation-delay:0s]">
        Book
      </a>
      {/* Shop button - upper right */}
      <a 
        href="/shop" 
        className="absolute top-[41%] left-[78%] transform -translate-x-1/2 -translate-y-1/2 
                   w-16 h-16 bg-white text-black border-2 border-black rounded-full 
                   flex items-center justify-center no-underline cursor-pointer 
                   animate-float [animation-delay:0.8s]">
        Shop
      </a>
      {/* Services button - lower right */}
      <a 
        href="/services" 
        className="absolute top-[74%] left-[68%] transform -translate-x-1/2 -translate-y-1/2 
                   w-16 h-16 bg-white text-black border-2 border-black rounded-full 
                   flex items-center justify-center no-underline cursor-pointer 
                   animate-float [animation-delay:1.6s]">
        Services
      </a>
      {/* Info button - lower left */}
      <a 
        href="/info" 
        className="absolute top-[74%] left-[32%] transform -translate-x-1/2 -translate-y-1/2 
                   w-16 h-16 bg-white text-black border-2 border-black rounded-full 
                   flex items-center justify-center no-underline cursor-pointer 
                   animate-float [animation-delay:2.4s]">
        Info
      </a>
      {/* Stylists button - upper left */}
      <a 
        href="/stylists" 
        className="absolute top-[41%] left-[22%] transform -translate-x-1/2 -translate-y-1/2 
                   w-16 h-16 bg-white text-black border-2 border-black rounded-full 
                   flex items-center justify-center no-underline cursor-pointer 
                   animate-float [animation-delay:3.2s]">
        Stylists
      </a>
    </div>
  );
};

export default HomePage;
